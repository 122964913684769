import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/Montserrat-Light.otf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Montserrat-Regular.otf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Montserrat-Bold.otf\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Montserrat-Black.otf\",\"weight\":\"900\",\"style\":\"normal\"}],\"variable\":\"--font-montserrat\"}],\"variableName\":\"Montserrat\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/common/ServerIntlProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["CartModalProvider"] */ "/app/src/app/components/layout/CartModalProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/layout/footer-page.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/layout/header-mobile.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/layout/header-nav.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/layout/LoaderWrapper.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/GoogleAnalytics.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/not-found.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/provider.jsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/global.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/fonts.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/components/header.module.scss");
